import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { useOrganizationStore } from "../stores/organizationStore";
import clientService from "../services/clientService";
import { useEffect } from "react";
import { useClientStore } from "../stores/clientStore";
import use from "react";

export type CompanySearchDTOType = {
  company_name: string;
  company_url?: string | undefined;
  company_address?: string | undefined;
  company_city?: string | undefined;
  company_province?: string | undefined;
  company_zip_code?: string | undefined;
  company_countries?: string | undefined;
};

const useCompanySearch = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: async ({ data }: { data: any }) =>
      clientService.companySearch(data),
    onSuccess: (data: any) => {
      console.log("Mutation Success:", data);
      queryClient.invalidateQueries({ queryKey: ["outgoingInvitations"] });
    },
    onError: (error) => {
      if (process.env.NODE_ENV === "development") {
        console.error("Mutation Error:", error);
      }
    },
  });
  return {
    mutateAsync,
    isPending: isPending,
    isError: isError,
    error: error,
  };
};

const useSearchInternalDatabaseForClient = (): any => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: async ({ clientSearchDTO }: { clientSearchDTO: any }) =>
      clientService.searchInternalDatabaseForClient({ clientSearchDTO }),
    onSuccess: (data: any) => {
      console.log("Mutation Success:", data);
      queryClient.invalidateQueries({ queryKey: ["clientSearch"] });
    },
    onError: (error) => {
      if (process.env.NODE_ENV === "development") {
        console.error("Mutation Error:", error);
      }
    },
  });
  return {
    mutateAsync,
    isPending: isPending,
    isError: isError,
    error: error,
  };
};

const useCreateNewClient = (): any => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: async (connectId: string) =>
      clientService.createNewClient(connectId),
    onSuccess: (data: any) => {
      console.log("Mutation Success:", data);
    },
    onError: (error) => {
      if (process.env.NODE_ENV === "development") {
        console.error("Mutation Error:", error);
      }
    },
  });
  return {
    mutateAsync,
    isPending: isPending,
    isError: isError,
    error: error,
  };
};

const useGetClientByClientId = (clientId: number) => {
  const queryClient = useQueryClient();

  const { data, error, isPending, isSuccess, isError } = useQuery({
    queryKey: ["selectedClient", clientId],
    queryFn: () => clientService.getClientByClientId(clientId),
    staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
    refetchOnWindowFocus: false, // refetching on window focus can be too aggressive, so it's turned off
    refetchOnReconnect: true, // refetch when the connection is restored
    refetchOnMount: true, // only refetch when parameters change, not every mount
    retry: 1, // retry failed queries once before throwing an error
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000), // use exponential backoff for retry delays
    enabled: !!clientId, // only run the query if connectionId is available
  });

  if (error) {
    console.error("Query Error:", error);
  }
  return {
    data,
    isLoading: isPending,
    isError: isError,
    error: error,
    isSuccess: isSuccess,
  };
};

export {
  useGetClientByClientId,
  useCreateNewClient,
  useSearchInternalDatabaseForClient,
  useCompanySearch,
};
