import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

export interface IOrganizationProfile {
  organizationId: string;
  totalCreditExtended: number;
  totalCreditUtilized: number;
  averageCreditPulseScore: number;
  averageCreditPulseScore30DayChange: number;
  overallRiskSummary: IRiskSummaryItem[];
  industryRiskSummary: IIndustryRiskSummary[];
  createdAt: string;
  updatedAt: string;
}

interface IRiskSummaryItem {
  id: number;
  high: number;
  highCreditUtilized: number;
  highCreditExtended: number;
  medium: number;
  mediumCreditUtilized: number;
  mediumCreditExtended: number;
  low: number;
  lowCreditUtilized: number;
  lowCreditExtended: number;
  total: number;
  organizationProfileId: number;
  createdAt: string;
  updatedAt: string;
}

interface IIndustryRiskSummary {
  id: number;
  organizationProfileId: number;
  total: number;
  createdAt: string;
  updatedAt: string;
  riskList: IIndustryRiskItem[];
}

interface IIndustryRiskItem {
  id: number;
  averageCreditPulseScore: number;
  totalClients: number;
  totalUtilizedCredit: number;
  totalExtendedCredit: number;
  averageDbt: number;
  industryDbt: number;
  industry: string;
  high: number;
  medium: number;
  low: number;
  industryRiskSummaryId: number;
  createdAt: string;
  updatedAt: string;
}

interface OrganizationState {
  organizationProfiles: IOrganizationProfile[];
}

interface OrganizationActions {
  setOrganizationProfiles: (profiles: IOrganizationProfile[]) => void;
}

const INITIAL_STATE: OrganizationState = {
  organizationProfiles: [],
};

type OrganizationStore = OrganizationState & OrganizationActions;

export const useOrganizationStore = create<OrganizationStore>((set) => {
  let organizationProfiles = [];

  // Only attempt to access localStorage when on the client side
  if (typeof window !== "undefined") {
    const savedOrganizationProfiles = localStorage.getItem(
      "organizationProfiles"
    );
    organizationProfiles = savedOrganizationProfiles
      ? JSON.parse(savedOrganizationProfiles)
      : [];
  }

  return {
    organizationProfiles,

    setOrganizationProfiles: (profiles: IOrganizationProfile[]) => {
      // Only modify localStorage when on the client side
      if (typeof window !== "undefined") {
        localStorage.setItem("organizationProfiles", JSON.stringify(profiles));
      }
      set({ organizationProfiles: profiles });
    },
  };
});

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("OrganizationStore", useOrganizationStore);
}
