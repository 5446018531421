import { CompanySearchDTOType } from "../hooks/clientHook";
import authAxiosInstance from "../lib/interceptors/apiInterceptor";

class ClientService {
  async getAllClientsByOrganizationId({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<any> {
    try {
      const response = await authAxiosInstance.get(
        `/organization/${organizationId}/clients`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getClientByClientId(clientId: number): Promise<any> {
    try {
      const response = await authAxiosInstance.get(`/client/${clientId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async searchInternalDatabaseForClient({
    clientSearchDTO,
  }: {
    clientSearchDTO: any;
  }): Promise<any> {
    console.log("clientSearchDTO to service", clientSearchDTO);
    try {
      const response = await authAxiosInstance.post(`/client`, {
        ...clientSearchDTO,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async companySearch(data: CompanySearchDTOType): Promise<any> {
    try {
      let apiUrl = `/client/companySearch`;
      if (data.company_name) {
        apiUrl += `?name=${encodeURIComponent(data.company_name)}`;
      }
      if (data.company_address) {
        apiUrl += `&address=${encodeURIComponent(data.company_address)}`;
      }
      if (data.company_city) {
        apiUrl += `&city=${encodeURIComponent(data.company_city)}`;
      }
      if (data.company_province) {
        apiUrl += `&province=${encodeURIComponent(data.company_province)}`;
      }
      if (data.company_zip_code) {
        apiUrl += `&postcode=${encodeURIComponent(data.company_zip_code)}`;
      }
      if (data.company_url) {
        apiUrl += `&website=${encodeURIComponent(data.company_url)}`;
      }
      if (data.company_countries) {
        apiUrl += `&countries=${encodeURIComponent(data.company_countries)}`;
      }

      const response = await authAxiosInstance.post(apiUrl);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createNewClient(connectId: string): Promise<any> {
    try {
      const response = await authAxiosInstance.post(
        `/client/${connectId}`,
        connectId
      );
      return response.data;
    } catch (error) {
      console.error("Failed to create new client:", error);
      throw error;
    }
  }
}

export default new ClientService();
