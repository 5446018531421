import React from "react";
import { DotIndicator } from "@/app/components/DotIndicator/DotIndicator";
import { defaultInfographicColors } from "@/app/lib/design/infographicStyles";
import { useOrganizationStore } from "@/app/stores/organizationStore";
import HorizontalSegmentedBar, {
  Segment,
} from "@/app/components/HorizontalSegmentBar/HorizontalSegmentedBar";

export type IndustryRisk = {
  industry: string;
  low: number;
  medium: number;
  high: number;
  averageCreditPulseScore: number;
  totalUtilizedCredit: number;
  totalExtendedCredit: number;
  totalClients: number;
};

export type OrganizationProfile = {
  industryRiskSummary?: {
    riskList: IndustryRisk[];
  }[];
};

export type IndustryRiskTableProps = {
  organizationProfile?: OrganizationProfile;
};

const renderRiskBadge = (low: number, medium: number, high: number) => {
  if (high > 0) {
    return (
      <span className="inline-flex items-center text-xs font-medium bg-red-100 px-2 py-1 mx-1 text-red-700 ring-1 ring-inset ring-pink-700/10 rounded-full">
        High
      </span>
    );
  } else if (medium > 0) {
    return (
      <span className="inline-flex items-center text-xs font-medium bg-yellow-100 px-2 py-1 mx-1 text-yellow-700 ring-1 ring-inset ring-yellow-700/10 rounded-full">
        Medium
      </span>
    );
  } else {
    return (
      <span className="inline-flex items-center text-xs font-medium bg-green-100 px-2 py-1 mx-1 text-green-700 ring-1 ring-inset ring-green-700/10 rounded-full">
        Low
      </span>
    );
  }
};

const transformRiskDataToSegments = (riskList: IndustryRisk[]): Segment[] => {
  return riskList.map((risk, index) => {
    const color =
      defaultInfographicColors[index % defaultInfographicColors.length];
    return {
      value: risk.totalUtilizedCredit,
      color: color,
      label: `${risk.industry}: ${new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(risk.totalUtilizedCredit)}`,
    };
  });
};

const formatCreditExtended = (amount: number): string => {
  if (amount >= 1_000_000_000) {
    return `$${(amount / 1_000_000_000).toFixed(1)}B`;
  } else if (amount >= 1_000_000) {
    return `$${(amount / 1_000_000).toFixed(1)}M`;
  } else if (amount >= 1_000) {
    return `$${(amount / 1_000).toFixed(1)}K`;
  } else {
    return `$${amount.toFixed(0)}`;
  }
};

const IndustryRiskTable = () => {
  // Get the organization profile from the store
  const { organizationProfiles } = useOrganizationStore();

  const organizationProfile = organizationProfiles[0];

  // Check if the necessary data is available
  if (
    !organizationProfile ||
    !organizationProfile.industryRiskSummary ||
    organizationProfile.industryRiskSummary.length === 0 ||
    !organizationProfile.industryRiskSummary[0].riskList
  ) {
    return <div>No risk data available.</div>;
  }

  const riskList = organizationProfile.industryRiskSummary[0].riskList;
  const totalCreditExtendedFormatted = formatCreditExtended(
    organizationProfile.totalCreditExtended
  );

  // Transform risk data to segments for HorizontalSegmentedBar
  const segments = transformRiskDataToSegments(riskList);

  return (
    <>
      {/* Title Row */}
      <div className="flex flex-row justify-between">
        <div className="basis-1/2">
          <h3 className="font-bold">Risk Exposure By Industry</h3>
        </div>
        <div className="basis-1/2">
          <HorizontalSegmentedBar segments={segments} />
        </div>
      </div>
      {/* Subtitle Row */}
      <div className="flex flex-row">
        <span className="font-bold text-3xl">
          {totalCreditExtendedFormatted}
        </span>
        <h4 className="text-secondary-text mt-3 ml-1">Total Exposure</h4>
      </div>
      {/* Divider */}
      <div className="border-b border-gray-300 mt-3"></div>
      <div className="flex flex-row">
        <div className="basis-3/12">
          <span className="font-bold text-center">Industry Category</span>
          {riskList.map((risk, index) => {
            const colorClass =
              defaultInfographicColors[index % defaultInfographicColors.length];
            return (
              <div key={index} className="flex flex-row py-1">
                <DotIndicator color={colorClass} className="mt-1 mr-1" />
                <span className="truncate w-40 overflow-hidden">
                  {risk.industry.trim()}
                </span>
              </div>
            );
          })}
        </div>
        <div className="basis-2/12">
          <span className="font-bold text-center">Avg Score</span>
          {riskList.map((risk, index) => {
            return (
              <div key={index} className="flex flex-row py-1">
                {Math.round(risk.averageCreditPulseScore)}
              </div>
            );
          })}
        </div>
        <div className="basis-2/12">
          <span className="font-bold">Risk Level</span>
          {riskList.map((risk, index) => {
            return (
              <div key={index} className="flex flex-row py-1">
                {renderRiskBadge(risk.low, risk.medium, risk.high)}
              </div>
            );
          })}
        </div>
        <div className="basis-2/12">
          <span className="font-bold text-center"># of Accts</span>
          {riskList.map((risk, index) => {
            return (
              <div key={index} className="flex flex-row py-1">
                {Math.round(risk.totalClients)}
              </div>
            );
          })}
        </div>
        <div className="basis-3/12">
          <span className="font-bold text-center">Credit Utilized</span>
          {riskList.map((risk, index) => {
            return (
              <div key={index} className="flex flex-row py-1">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(Number(risk.totalUtilizedCredit))}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default IndustryRiskTable;
