"use client";

import { useState, useEffect } from "react";
import { withPageAuthRequired } from "@auth0/nextjs-auth0/client";
import { useUser } from "@auth0/nextjs-auth0/client";
import { PlusCircle } from "@untitled-ui/icons-react";

import "./style.css";
import { useSettingsStore } from "@/app/stores/settingsStore";

import { useRouter } from "next/navigation";
import Panel from "@/app/components/Panel/Panel";
import { DotIndicator } from "@/app/components/DotIndicator/DotIndicator";
import { ProgressBar } from "@/app/components/ProgressBar/ProgressBar";
import Image from "next/image";

import CreditPulseScoreRadialWheel from "@/app/components/CreditPulseScoreRadialWheel/CreditPulseScoreRadialWheel";
import RiskSummaryGraph from "./RiskSummaryGraph";
import IndustryRiskTable from "./IndustryRiskTable";
import { getFirstDayOfNextMonth } from "@/app/lib/utils/dateFunctions";
import PortfolioRiskGraph from "./PortfolioRiskSummary";
import { useOrganizationStore } from "@/app/stores/organizationStore";
import {
  useGetClientsByOrganizationId,
  useGetOrganizationProfile,
} from "@/app/hooks/organizationHook";
import LoadingAnimation from "@/app/components/LoadingAnimation/LoadingAnimation";
import MiniRiskLevelTable from "./MiniRiskLevelTable";
import { CompaniesToWatchTable } from "./companiesToWatchTable";
import Typography from "@/app/components/Typography/Typography";
import { Button } from "@/app/components/Button/Button";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const Home = () => {
  // Application Settings State
  const { settings, selectSettings } = useSettingsStore();
  const router = useRouter();

  // User State
  const { user } = useUser();

  // Fetch clients by organization id
  const { data: clientsData } = useGetClientsByOrganizationId(
    user?.org_id || ""
  );

  const { data, isSuccess, isLoading } = useGetOrganizationProfile({
    organizationId: user?.org_id || "",
  });

  // Get organization Profile
  const { organizationProfiles } = useOrganizationStore();

  const renderPercentageString = (value: number) => {
    if (value < 0) {
      return <span className="font-bold text-red-400">{value}%</span>;
    }
    if (value > 0) {
      return <span className="font-bold text-green-400">{value}%</span>;
    } else {
      return <span className="font-bold text-secondary-text">{value}%</span>;
    }
  };

  if (isLoading) {
    return <LoadingAnimation />;
  }

  console.log("data", data);

  if (data && data?.length === 0) {
    return (
      <>
        <div className="flex items-center justify-center text-center mt-10">
          <div>
            <Typography fontStyling="bold" variant="h2" colour="secondary">
              No Clients Currently Monitored
            </Typography>
            <Typography fontStyling="bold" variant="p" colour="tertiary">
              Please add a client to monitor on the Client Management page.
            </Typography>
          </div>
        </div>
      </>
    );
  }

  if (
    isSuccess &&
    organizationProfiles &&
    data &&
    organizationProfiles.length > 0
  ) {
    return (
      <main>
        {/* Main Content */}
        <div
          className={`pt-5 pb-10 ${
            settings.isSidebarOpen ? "container" : "container-collapsed"
          }`}
        >
          {/* Title Row */}
          <div className="flex items-center justify-between">
            {/* Left Title Side */}
            <div>
              <Typography variant="h1" colour="primary">
                Portfolio
              </Typography>
              <Typography variant="h6" colour="secondary" className="mb-2">
                Updated Monthly - Next updated on {getFirstDayOfNextMonth()}
              </Typography>
              <Button
                onClick={(e) => {
                  router.push("/clientManagement/addClient");
                }}
              >
                <PlusCircle className="mr-2" /> Add New Client
              </Button>
            </div>
            {/* Right Credit Utilization Side */}
            <div className="">
              <Panel>
                {/* Title */}
                <div className="flex-row">
                  <Typography
                    variant="h5"
                    colour="primary"
                    fontStyling="bold"
                    className="ml-2"
                  >
                    Credit Extended vs. Utilized
                  </Typography>
                </div>
                {/* Credit values */}
                <div className="flex flex-row items-center">
                  <span className="flex items-center">
                    <DotIndicator color="bg-infographic-primary-green" />
                    <Typography
                      variant="main"
                      colour="primary"
                      className="ml-2"
                    >
                      Utilized:
                    </Typography>{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(
                      Number(organizationProfiles[0]?.totalCreditUtilized)
                    )}
                  </span>
                  <span className="flex items-center ml-4">
                    <DotIndicator color="bg-infographic-secondary-green" />
                    <Typography
                      variant="main"
                      colour="primary"
                      className="ml-2"
                    >
                      Extended:
                    </Typography>{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(
                      Number(organizationProfiles[0].totalCreditExtended)
                    )}
                  </span>
                </div>

                {/* Credit Extended vs. Utilized Progress Bar */}
                <div className="flex-row">
                  <ProgressBar
                    totalValue={organizationProfiles[0].totalCreditExtended}
                    currentValue={organizationProfiles[0].totalCreditUtilized}
                    progressColor="bg-infographic-primary-green"
                    backgroundColor="bg-infographic-secondary-green"
                  />
                </div>
              </Panel>
            </div>
          </div>
          {/* Infographic Panels */}
          {/* Top Infographic Panel Row */}
          <div className="flex flex-row gap-5 mt-5">
            {/* Left Current Overall Risk Summary Panel */}
            <Panel className="basis-1/4 w-full">
              <div className="flex flex-row">
                <h2 className="font-bold">Risk Summary</h2>
              </div>
              <div className="flex flex-row">
                <span className="text-red-400 font-bold">
                  {renderPercentageString(
                    organizationProfiles[0].averageCreditPulseScore30DayChange
                  )}
                </span>{" "}
                <span className="text-secondary-text ml-3">Last 30 Days</span>{" "}
              </div>
              {(!organizationProfiles[0].averageCreditPulseScore) && (
                <div className="bg-gray-100 text-gray-700 rounded-t rounded-b px-4 py-10 my-5 border" role="alert">
                  <p className="font-bold">Risk score is not available yet</p>
                </div>
              )}
              {(!!organizationProfiles[0].averageCreditPulseScore) && (
                <CreditPulseScoreRadialWheel
                  score={organizationProfiles[0].averageCreditPulseScore}
                />
              )}
              <MiniRiskLevelTable />
            </Panel>
            {/* Right Previous Overall Risk Summary Panel */}
            <Panel className="basis-3/4 w-full">
              {/* Title Row */}
              <div className="flex flex-row justify-between">
                <div>
                  <h2 className="font-bold">Historical Risk Summary</h2>
                </div>
                <div>
                  {/* Risk Badge Legend */}
                  <span className="inline-flex items-center text-xs font-medium bg-green-100 px-2 py-1 mx-1 text-green-700 ring-1 ring-inset ring-green-700/10 rounded-full">
                    Low
                  </span>
                  <span className="inline-flex items-center text-xs font-medium bg-yellow-100 px-2 py-1 mx-1 text-yellow-700 ring-1 ring-inset ring-yellow-700/10 rounded-full">
                    Medium
                  </span>
                  <span className="inline-flex items-center text-xs font-medium bg-red-100 px-2 py-1 mx-1 text-red-700 ring-1 ring-inset ring-pink-700/10 rounded-full">
                    High
                  </span>
                </div>
              </div>
              {/* Subtitle Row */}
              <div className="flex flex-row">
                <h3 className="text-secondary-text">
                  Breakdown of portfolio risk level over the last 12 months
                </h3>
              </div>
              {/* Overall Risk Summary Graph */}
              <div style={{ width: "100%", height: "300px" }}>
                <RiskSummaryGraph />
              </div>
            </Panel>
          </div>
          {/* Middle Infographic Panel Row */}
          <div className="flex flex-row gap-5 mt-5">
            {/* Left Portfolio DBT Risk Summary Panel */}
            <Panel className="basis-1/2 w-full">
              {/* TODO: FIX THIS */}
              <PortfolioRiskGraph />
            </Panel>
            {/* Right Risk Exposure By Industry Panel */}
            <Panel className="basis-1/2 w-full">
              {/* Industry Risk Table */}
              <IndustryRiskTable />
            </Panel>
          </div>
          {/* Bottom Infographic Panel Row */}
          <div className="flex flex-row mt-5 w-full">
            <CompaniesToWatchTable />
          </div>
        </div>
      </main>
    );
  }
};

export default withPageAuthRequired(Home);
